import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';
import { EmailService } from './../../services/email.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [
    CommonModule,
    EditorModule,
    FormsModule,
  ],
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})

export class PricingComponent implements OnInit {
  responsiveDesignCost: number = 0;
  responsiveDesignValue: string = '';

  retinaDesignCost: number = 0;
  retinaDesignValue: string ='';

  websiteTypeCost: number = 0;
  websiteTypeValue: string ='';

  styleTypeCost: number = 0;
  styleTypeValue: string = '';

  numOfWebPages: number = 10;
  numOfWebPagesCost: number = 1000;

  numOfFormPages: number = 0;
  numOfFormPagesCost: number = 0;

  CMSTypeCost: number = 0;
  CMSTypeValue: string = '';

  NumOfSEOCost: number = 0;
  numOfSEOPlacements: number = 0;

  GoogleAnalyticsCost: number = 0;
  GoogleAnalyticsValue: string = '';

  DomainNameCost: number = 0;
  DomainNameValue: string = '';

  WebHostingCost: number = 0;
  WebHostingValue: string = '';
  
  RushCost: number = 0;
  RushValue: string = '';

  totalDesignCost: number = 1000;

  name: string = '';
  email: string = '';
  phone: string = '';
  message: string = '';

  constructor(
    private emailService: EmailService, 
    private router: Router,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Walentik Software LLC - Website Pricing'); // Set the page title
  }

  async onSubmit() {
    this.emailService.sendEmail([this.email], "Thank you for your Submission", this.createEmailBody())
      .subscribe({
        next: (response: any) => {
          console.log('Email sent', response);
        },
        error: (error: any) => {
          console.error('Failed to send email', error);
        }
      });

    this.emailService.sendEmail([ "stephen@walentik.net" ], "New Submission: Website Pricing", this.createEmailBody())
      .subscribe({
        next: (response: any) => {
          console.log('Email sent', response);
        },
        error: (error: any) => {
          console.error('Failed to send email', error);
        }
      });

    // Navigate to the home page after a short delay
    setTimeout(() => {
      this.router.navigate(['/home']);
    }, 2000);
  }

  createEmailBody(): string {
    const emailBody = `
      <html>
        <body>
          <h2>Customer Information:</h2>
          <p>Name: ${this.name}</p>
          <p>Email: ${this.email}</p>
          <p>Phone: ${this.phone}</p>
          <p>Message: ${this.message}</p>
          
          <h2>Website Information:</h2>
          <p>Responsive Design : ${this.responsiveDesignValue}</p>
          <p>Responsive Design Cost: $${this.responsiveDesignCost}</p>
          <p>Retina Design Value: ${this.retinaDesignValue}</p>
          <p>Retina Design Cost: $${this.retinaDesignCost}</p>
          <p>Website Type Value: ${this.websiteTypeValue}</p>
          <p>Website Type Cost: $${this.websiteTypeCost}</p>
          <p>Style Type Value: ${this.styleTypeValue}</p>
          <p>Style Type Cost: $${this.styleTypeCost}</p>
          <p>Number of Web Pages: ${this.numOfWebPages}</p>
          <p>Number of Web Pages Cost: $${this.numOfWebPagesCost}</p>
          <p>Number of Forms: ${this.numOfFormPages}</p>
          <p>Number of Forms Cost: $${this.numOfFormPagesCost}</p>
          <p>CMS Type Cost: $${this.CMSTypeCost}</p>
          <p>Number of SEO Placements: ${this.numOfSEOPlacements}</p>
          <p>Number of SEO Cost: $${this.NumOfSEOCost}</p>
          <p>Google Analytics Value: ${this.GoogleAnalyticsValue}</p>
          <p>Google Analytics Cost: $${this.GoogleAnalyticsCost}</p>
          <p>Domain Name Value: ${this.DomainNameValue}</p>
          <p>Domain Name Cost: $${this.DomainNameCost}/year</p>
          <p>Web Hosting Value: ${this.WebHostingValue}</p>
          <p>Web Hosting Cost: $${this.WebHostingCost}/year</p>
          <p>Rush Value: ${this.RushValue}</p>
          <p>Rush Cost: $${this.RushCost}</p>
          <p>Total Design Cost: $${this.totalDesignCost}</p>
        </body>
      </html>
    `;
    
    return emailBody;
  }


  updateResponsiveDesignCost(value: any) {
    this.responsiveDesignValue = value.target.value;
    let delta = this.responsiveDesignCost;
    this.responsiveDesignCost = this.responsiveDesignValue === 'Desktop + Mobile' ? 1000 : 0;
    delta = this.responsiveDesignCost - delta;
    this.totalDesignCost += delta;
  }

  
  updateRetinaDesignCost(value: any) {
    this.retinaDesignValue = value.target.value;
    let delta = this.retinaDesignCost;
    this.retinaDesignCost = this.retinaDesignValue === 'Yes Please!' ? 1000 : 0;
    delta = this.retinaDesignCost - delta;
    this.totalDesignCost += delta;
  }

  
  updateWebsiteTypeCost(value: any) {
    this.websiteTypeValue = value.target.value;
    let delta = this.websiteTypeCost;

    switch (this.websiteTypeValue) {
      case 'Corporate':
        this.websiteTypeCost = 200;
        break;
      case 'eCommerce':
        this.websiteTypeCost = 400;
        break;
      case 'Blog':
        this.websiteTypeCost = 100;
        break;
      case 'Personal':
        this.websiteTypeCost = 80;
        break;
      case 'Database':
        this.websiteTypeCost = 300;
        break;
      default:
        this.websiteTypeCost = 0;
        break;
    }

    delta = this.websiteTypeCost - delta;
    this.totalDesignCost += delta;
  }

  
  updateStyleTypeCost(value: any) {
    this.styleTypeValue = value.target.value;
    let delta = this.styleTypeCost;

    switch (this.styleTypeValue) {
      case 'Basic':
        this.styleTypeCost = 0;
        break;
      case 'Flat':
        this.styleTypeCost = 500;
        break;
      case 'Modern':
        this.styleTypeCost = 1500;
        break;
      default:
        this.styleTypeCost = 0;
        break;
    }

    delta = this.styleTypeCost - delta;
    this.totalDesignCost += delta;
  }

  
  updateNumOfPagesCost() {
    let delta = this.numOfWebPagesCost;

    this.numOfWebPagesCost = 100 * this.numOfWebPages;

    delta = this.numOfWebPagesCost - delta;
    this.totalDesignCost += (delta);
  }

  
  updateNumOfFormPagesCost() {
    let delta = this.numOfFormPagesCost;

    this.numOfFormPagesCost = 500 * this.numOfFormPages;

    delta = this.numOfFormPagesCost - delta;
    this.totalDesignCost += (delta);
  }

  
  updateCMSTypeCost(value: any) {
    this.CMSTypeValue = value.target.value;
    let delta = this.CMSTypeCost;

    switch (this.CMSTypeValue) {
      case 'None':
        this.CMSTypeCost = 0;
        break;
      case 'Wordpress':
        this.CMSTypeCost = 1200;
        break;
      case 'Headless API':
        this.CMSTypeCost = 2000;
        break;
      default:
        this.CMSTypeCost = 0;
        break;
    }

    delta = this.CMSTypeCost - delta;
    this.totalDesignCost += delta;
  }

  
  updateNumOfSEOCost() {
    let delta = this.NumOfSEOCost;

    this.NumOfSEOCost = 100 * this.numOfSEOPlacements;

    delta = this.NumOfSEOCost - delta;
    this.totalDesignCost += (delta);
  }

  
  updateGoogleAnalyticsCost(value: any) {
    this.GoogleAnalyticsValue = value.target.value;
    let delta = this.GoogleAnalyticsCost;

    this.GoogleAnalyticsCost = this.GoogleAnalyticsValue === 'Yes' ? 100 : 0;

    delta = this.GoogleAnalyticsCost - delta;
    this.totalDesignCost += (delta);
  }

  
  updateDomainNameCost(value: any) {
    this.DomainNameValue = value.target.value;
    let delta = this.DomainNameCost;

    this.DomainNameCost = this.DomainNameValue === 'No' ? 50 : 0;

    delta = this.DomainNameCost - delta;
    this.totalDesignCost += (delta);
  }

  
  updateWebHostingCost(value: any) {
    this.WebHostingValue = value.target.value;
    let delta = this.WebHostingCost;

    this.WebHostingCost = this.WebHostingValue === 'Yes' ? 100 : 0;

    delta = this.WebHostingCost - delta;
    this.totalDesignCost += (delta);
  }

  
  updateRushCost(value: any) {
    this.RushValue = value.target.value;
    let delta = this.RushCost;

    this.RushCost = this.RushValue === "Yes" ? 1000 : 0;

    delta = this.RushCost - delta;
    this.totalDesignCost += (delta);
  }
}
