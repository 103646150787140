import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailService } from '../../../../services/email.service';

@Component({
  selector: 'app-frycards-feedback-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './frycards-feedback-form.component.html',
  styleUrls: ['./frycards-feedback-form.component.css']
})
export class FryCardsFeedbackFormComponent {
  loading = false;
  submitted = false;
  returnUrl: string = '';
  error: string= '';
  success: string= '';

  public form: FormGroup;
  public feedbackFirstName: string= '';
  public feedbackLastName: string= '';
  public feedbackEmailAddress: string= '';
  public feedbackDetails: string= '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private emailService: EmailService,
  ){
    this.form = this.formBuilder.group({
      feedbackFirstName: ['', Validators.required],
      feedbackLastName: ['', Validators.required],
      feedbackEmailAddress: ['', Validators.required],
      feedbackDetails: ''
    });
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.error = '';
    this.success = '';

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    let body = "Name: " + this.f['feedbackFirstName'].value + " " + this.f['feedbackLastName'].value + "<br />"
        + "Email: " + this.f['feedbackEmailAddress'].value + "<br />"
        + "Feedback: " + this.f['feedbackDetails'].value;

    this.sendEmail(["frycards@walentik.net"], "Feedback Provided for Fry Cards", body);
    this.sendEmail([this.f['feedbackEmailAddress'].value], "Feedback Provided for Fry Cards", body);

    this.loading = false;

    setTimeout(() => {
      this.reload();
    }, 2000);
  }

  sendEmail(to: string[], subject: string, body: string) {
    this.emailService.sendEmail(to, subject, body).subscribe(
      response => {
        console.log(response);
    });
  }

  reload() {
    let currentUrl = this.router.url;
    this.router.navigate([currentUrl])
      .then(() => {
        window.location.reload();
      });
  }
}
