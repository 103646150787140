import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { BlogService, Blog } from 'src/app/services/blog.service';
import { Title } from '@angular/platform-browser';
import { HttpEventType } from '@angular/common/http';
import { FileUploaderComponent } from 'src/app/components/file-uploader/file-uploader.component';

@Component({
  selector: 'app-blog-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class NewBlogComponent implements OnInit {
  @ViewChild('fileUploadComponent1') fileUploadComponent1!: FileUploaderComponent;
  blog!: Blog;
  errorText: string = null!;

  constructor(
    private blogService: BlogService,
    private titleService: Title,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    if(!this.authService.isAdministrator()){
      window.location.href = '/account/login';
    }
    this.titleService.setTitle('Walentik Software LLC - New Blog - '); // Set the page title
    this.blog = new Blog();
    this.blog.content = '';
    this.blog.title = '';
    this.blog.description = '';
    this.blog.author = '';
  }

  onSubmit(){
    this.createBlogPost();
  }

  createBlogPost(){
    this.blogService.createBlog(this.blog).subscribe({
      next: (response: any) => {
        console.log(response);
        if (!!response) {
          if (!!response.error) {
            this.errorText = response.error.title + ": " + response.error.detail;
            console.log(this.errorText);
          }
          else {
            console.log("Successful Create");
            window.location.href = '/blog/' + this.blog.id;
          } 
        }
      },
      error: (error: any) => {
        console.log("Error during create blog");
      }
    });
  }
  
  onNewUploadAddFile(event: any) {
    this.blog.displayImage = event.fullPath.substring(8);
  }
}
