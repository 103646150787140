<div class="container mt-5">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Login</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form>
                        <div class="mb-3">
                            <mat-form-field appearance="fill">
                                <mat-label>Email</mat-label>
                                <input matInput placeholder="Enter your email" [(ngModel)]="email" name="email">
                            </mat-form-field>
                        </div>
                        <div class="mb-3">
                            <mat-form-field appearance="fill">
                                <mat-label>Password</mat-label>
                                <input matInput type="password" placeholder="Enter your password" [(ngModel)]="password" name="password">
                            </mat-form-field>
                        </div>
                        <div class="mb-3">
                            <span *ngIf="errorText" style="color: red">{{ errorText }}</span>
                        </div>
                        <button mat-raised-button color="primary" type="submit" (click)="login()">Login</button>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
  