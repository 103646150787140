<div class="container mt-3">
    <mat-card>
      <mat-card-header>
        <mat-card-title><h1>User Account Details</h1></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form>
            <p><strong>Username:</strong> {{user.email}}</p>
            <p><strong>Email:</strong> {{user.email}}</p>
            <p><strong>Role:</strong> {{user.role}}</p>
            
            <!-- Add more user details here -->
            <button mat-raised-button color="primary" type="submit" (click)="logout()">Logout</button>
        </form>
      </mat-card-content>
    </mat-card>
  
    <!-- Additional sections like account settings or user statistics can go here -->
  </div>
  