import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) { }

  getBlogs(): Observable<Blog[]> {
    const blogUrl = this.baseUrl + 'api/blog/all';

    return this.http.get(blogUrl, { reportProgress: true, observe: 'events' })
      .pipe(
        map((response: any) => {
          if (response.type === HttpEventType.Response) {
            
            return response.body;
          }
          return null;
        }),
        catchError((errResponse: any) => {
          console.log(errResponse);
          return of(errResponse);
        })
      );
  }

  getBlog(id: number): Observable<Blog> {
    const blogUrl = this.baseUrl + 'api/blog/' + id;

    return this.http.get(blogUrl, { reportProgress: true, observe: 'events' })
      .pipe(
        map((response: any) => {
          if (response.type === HttpEventType.Response) {
            
            return response.body;
          }
          return Blog;
        }),
        catchError((errResponse: any) => {
          console.log(errResponse);
          return of(errResponse);
        })
      );
  }

  updateBlog(id: number, blog: Blog): Observable<Blog> {
    const blogUrl = this.baseUrl + 'api/blog/' + id;

    return this.http.post(blogUrl, blog, { reportProgress: true, observe: 'events' })
      .pipe(
        map((response: any) => {
          if (response.type === HttpEventType.Response) {
            
            return response.body;
          }
          return null;
        }),
        catchError((errResponse: any) => {
          console.log(errResponse);
          return of(errResponse);
        })
      );
  }

  createBlog(blog: Blog): Observable<Blog> {
    const blogUrl = this.baseUrl + 'api/blog/new';

    return this.http.post(blogUrl, blog, { reportProgress: true, observe: 'events' })
      .pipe(
        map((response: any) => {
          if (response.type === HttpEventType.Response) {
            
            return response.body;
          }
          return null;
        }),
        catchError((errResponse: any) => {
          console.log(errResponse);
          return of(errResponse);
        })
      );
  }

  deleteBlog(id: number): Observable<boolean> {
    const blogUrl = this.baseUrl + 'api/blog/' + id;

    return this.http.delete(blogUrl, { reportProgress: true, observe: 'events' })
      .pipe(
        map((response: any) => {
          if (response.type === HttpEventType.Response) {
            
            return true;
          }
          return false;
        }),
        catchError((errResponse: any) => {
          console.log(errResponse);
          return of(false);
        })
      );
  }

  reactivateBlog(id: number): Observable<Blog> {
    const blogUrl = this.baseUrl + 'api/blog/' + id + '/reactivate';

    return this.http.post(blogUrl, {}, { reportProgress: true, observe: 'events' })
      .pipe(
        map((response: any) => {
          if (response.type === HttpEventType.Response) {
            
            return response.body;
          }
          return null;
        }),
        catchError((errResponse: any) => {
          console.log(errResponse);
          return of(errResponse);
        })
      );
  }
}

export class Blog {
  id!: number;
  created!: Date;
  updated!: Date;
  title!: string;
  description!: string;
  displayImage!: string;
  content!: string;
  callToAction!: string;
  author!: string;
  active!: boolean;
}