import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BlogService, Blog } from 'src/app/services/blog.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-blog-page',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.css']
})
export class BlogPageComponent implements OnInit {
  isEditable: boolean = false;
  blog!: Blog;
  id!: number;

  constructor(
    private route: ActivatedRoute,
    private blogService: BlogService,
    private titleService: Title,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isEditable = this.authService.isAdministrator();

    this.route.paramMap.subscribe(params => {
      this.id = Number(params.get('id'));
      this.blogService.getBlog(this.id).subscribe(blog => {
        this.blog = blog;
        this.titleService.setTitle('Walentik Software LLC - Blog - ' + blog.title); // Set the page title
      });
    });
  }

}
