import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { FryCardsSupportFormComponent } from './components/support-form/frycards-support-form.component';
import { FryCardsFeedbackFormComponent } from './components/feedback-form/frycards-feedback-form.component';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-frycards',
  standalone: true,
  imports: [
    CommonModule,
    FryCardsSupportFormComponent,
    FryCardsFeedbackFormComponent,
  ],
  templateUrl: './frycards.component.html',
  styleUrls: ['./frycards.component.css']
})
export class FrycardsComponent {
  environmentName: string = environment.name;

  constructor(private http: HttpClient, 
    private titleService: Title,
  ){
    this.titleService.setTitle('Walentik Software LLC - Fry Cards'); // Set the page title
  }

  onNgInit() {
    if (environment.name == "production"){
      this.environmentName = "";
    }
  }
}
