import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) { }

  login(email: string, password: string): Observable<any> {
    localStorage.setItem('identity:email', email);
    localStorage.setItem('identity:username', email);
    // replace with your actual authentication logic
    const loginUrl = this.baseUrl + 'api/identity/login';
    return this.http.post(loginUrl, { email: email, password: password }, { reportProgress: true, observe: 'events' })
      .pipe(
        map((response: any) => {
          if (response.type === HttpEventType.Response) {
            console.log(response);
            if (response.body.accessToken) {
              const now = new Date();
              const secondsToAdd = response.body.expiresIn;
              const futureTime = new Date(now.getTime() + secondsToAdd * 1000);
              localStorage.setItem('identity:accessToken', response.body.accessToken);
              //localStorage.setItem('identity:refreshToken', response.body.refreshToken);

              const jwtHelper = new JwtHelperService();
              const decodedToken = jwtHelper.decodeToken(response.body.accessToken);
              console.log(decodedToken);
              
              const role = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
              localStorage.setItem('identity:roles', role);
            }

            return response;
          }
          return null;
        }),
        catchError((errResponse: any) => {
          console.log(errResponse);
          return of(errResponse);
        })
      );
  }

  getUserName(): string | null {
    return localStorage.getItem('identity:username');
  }

  getEmail(): string | null {
    return localStorage.getItem('identity:email');
  }

  // Check if the user is authenticated
  isAuthenticated(): boolean {
    let token = this.getToken();

    if (!token) {
      return false;
    }

    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(token!);
    const expTime = new Date(decodedToken['exp'] * 1000);
    const isExpired = expTime.getTime() < Date.now();

    return !isExpired;
  }

  isAdministrator(): boolean {
    let token = this.getToken();

    if (!token) {
      return false;
    }
    
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(token!);
    const role = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

    return this.isAuthenticated() && !!role && role === "Admin";
  }

  // Get token
  getToken(): string | null {
    return localStorage.getItem('identity:accessToken');
  }

  // Logout method to clear the token
  logout(): void {
    localStorage.removeItem('identity:tokenType');
    localStorage.removeItem('identity:accessToken');
    localStorage.removeItem('identity:expiresIn');
    localStorage.removeItem('identity:refreshToken');
    localStorage.clear();
    // Redirect to login or home page
  }
  
  register(email: string, password: string): Observable<any> {
    // replace with your actual authentication logic
    const registerUrl = this.baseUrl + 'api/identity/register';
    return this.http.post(registerUrl, { email: email, password: password }, { reportProgress: true, observe: 'events' })
      .pipe(
        map((response: any) => {
          if (response.type === HttpEventType.Response) {
            return response;
          }
          return null;
        }),
        catchError((errResponse: any) => {
          console.log(errResponse);
          return of(errResponse);
        })
      );
  }
  
  refreshToken(): Observable<any> {
    // replace with your actual authentication logic
    const refreshTokenUrl = this.baseUrl + 'api/identity/refresh';
    let refreshToken = localStorage.getItem('identity:refreshToken');

    return this.http.post(refreshTokenUrl, { refreshToken: refreshToken }, { reportProgress: true, observe: 'events' })
      .pipe(
        map((response: any) => {
          if (response.type === HttpEventType.Response) {
            return response;
          }
          return null;
        }),
        catchError((errResponse: any) => {
          console.log(errResponse);
          return of(errResponse);
        })
      );
  }
  
  getAccountDetails(email: string): Observable<AccountDetails> {
    // replace with your actual authentication logic
    const getAccountDetailsUrl = this.baseUrl + 'api/identity/accountdetails/' + email;
    
    return this.http.get(getAccountDetailsUrl, { reportProgress: true, observe: 'events' })
      .pipe(
        map((response: any) => {
          if (response.type === HttpEventType.Response) {
            console.log(response);
            return response.body;
          }
          return null;
        }),
        catchError((errResponse: any) => {
          console.log(errResponse);
          return of(errResponse);
        })
      );
  }
}


export class AccountDetails {
  email!: string;
  userName!: string;
  role!: string;
}