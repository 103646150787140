<footer class="d-flex flex-wrap justify-content-between align-items-center p-3 border-top  walentik-dark-bg">
    <div class="col-md-4 d-flex align-items-center">
      <span class="text-white">© {{copyrightYearYYYY}}</span>&nbsp;<span class="walentik-blue">Walentik Software, LLC.</span>&nbsp;<span class="text-white">All rights reserved.</span>
    </div>
    <div class="d-flex align-items-center">
        <span class="walentik-blue">{{environmentName}}</span>
    </div>
    

    <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
        <li class="nav-item"><a [routerLink]="['/blog']" class="nav-link px-2 walentik-blue">Blog</a></li>
        <li class="nav-item"><a [routerLink]="['/privacy-policy']" class="nav-link px-2 walentik-blue">Privacy Policy</a></li>
        <li class="nav-item"><a [routerLink]="['/terms-and-conditions']" class="nav-link px-2 walentik-blue">Terms & Conditions</a></li>
    </ul>
</footer>