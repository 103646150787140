import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { FrycardsComponent } from './pages/frycards/frycards.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';
import { BlogPageComponent } from './pages/blog/blog-page/blog-page.component';
import { NewBlogComponent } from './pages/blog/new/new.component';
import { BlogComponent } from './pages/blog/blog.component';
import { EditBlogComponent } from './pages/blog/edit/edit.component';
import { LoginComponent } from './pages/account/login/login.component';
import { LogoutComponent } from './pages/account/logout/logout.component';
import { DetailsComponent } from './pages/account/details/details.component';
import { AuthGuard } from './auth-gaurd';

const routes: Routes = [
  { path: 'home', redirectTo: '', pathMatch: 'full' }, // redirect to `first-component`
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'frycards', component: FrycardsComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-and-conditions', component: TermsConditionsComponent },
  
  { path: 'blog', component: BlogComponent },
  { path: 'blog/new', component: NewBlogComponent },
  { path: 'blog/:id', component: BlogPageComponent },
  { path: 'blog/:id/edit', component: EditBlogComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  
  { path: 'account/login', component: LoginComponent },
  { path: 'account/logout', component: LogoutComponent },
  { path: 'account/logoff', component: LogoutComponent },
  { path: 'account/details', component: DetailsComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  
  

  { path: '**', redirectTo: '' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

