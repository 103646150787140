


<div class="container">
    <div class="row py-3">
        <div class="col-md-12">
            <mat-toolbar color="primary">
                <span>Create New Blog Post</span>
            </mat-toolbar>
            
            <mat-card>
                <mat-card-content>
                    <form name="add-new-blog" (ngSubmit)="onSubmit()">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Title</mat-label>
                            <input matInput [(ngModel)]="blog.title" name="title">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Description</mat-label>
                            <input matInput [(ngModel)]="blog.description" name="description">
                        </mat-form-field>
            
                        <mat-label>Default Image</mat-label>
                        <app-file-uploader (onUploadFinished)="onNewUploadAddFile($event)" [isMultiFile]="false" [fileDirectory]="'img/blog/'" #fileUploadComponent1></app-file-uploader>
            
                        <mat-form-field class="w-100 h-auto my-2" appearance="fill">
                            <mat-label>Default Image</mat-label>
                            <input matInput name="blogDisplayImage" [(ngModel)]="blog.displayImage" readonly>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Author</mat-label>
                            <input matInput [(ngModel)]="blog.author" name="author">
                        </mat-form-field>

                        <mat-label>Content</mat-label>
                        <div>
                            <quill-editor class="w-100" name="blogContent" [(ngModel)]="blog.content"></quill-editor>
                        </div>

                        <mat-form-field class="w-100 h-auto my-2" appearance="fill">
                            <mat-label>Content HTML</mat-label>
                            <textarea matInput name="blogContentHtml" [(ngModel)]="blog.content"></textarea>
                        </mat-form-field>

                        <mat-label>Call To Action</mat-label>
                        <div>
                            <quill-editor class="w-100" name="blogCallToAction" [(ngModel)]="blog.callToAction"></quill-editor>
                        </div>

                        <mat-form-field class="w-100 h-auto my-2" appearance="fill">
                            <mat-label>Call to Action HTML</mat-label>
                            <textarea matInput name="blogCallToActionHtml" [(ngModel)]="blog.callToAction"></textarea>
                        </mat-form-field>
            
                        <button mat-raised-button color="primary" type="submit">Submit</button>

                        <h1>Preview</h1>

                        <article class="blog-post">
                            <div class="container py-5" [innerHTML]="blog.content">
                        
                            </div>
                        </article>
                        <section class="cta-section bg-light">
                            <div class="container py-5" [innerHTML]="blog.callToAction">
                        
                            </div>
                        </section>
                    </form>
                </mat-card-content>
            </mat-card>



        </div>
    </div>
</div>