import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { BlogService, Blog } from 'src/app/services/blog.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  blogs: Blog[] = [];
  isEditable: boolean = false;

  constructor(
    private blogService: BlogService,
    private titleService: Title,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Walentik Software LLC - Blogs'); // Set the page title
    this.blogService.getBlogs().subscribe((blogs: Blog[]) => {
      this.blogs = blogs;
    });
    this.isEditable = this.authService.isAdministrator();
  }

}
