import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BlogComponent } from './pages/blog/blog.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth-interceptor';
import { FormsModule } from '@angular/forms'; // Needed for ngModel binding
import { QuillModule } from 'ngx-quill';
import { EditBlogComponent } from './pages/blog/edit/edit.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { NewBlogComponent } from './pages/blog/new/new.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    EditBlogComponent,
    NewBlogComponent,
    FileUploaderComponent,
    BlogComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }], // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }], // outdent/indent
          [{ 'direction': 'rtl' }], // text direction
          [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ 'color': [] }, { 'background': [] }], // dropdown with defaults
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['link', 'image', 'video', 'formula'],
          ['clean'] // remove formatting button
        ]
      }
    }),
    environment.production ? NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackingId) : [],
    environment.production ? NgxGoogleAnalyticsRouterModule : [],
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
