import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService, AccountDetails } from 'src/app/services/auth.service';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
  ],
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  user: User = {
    email: '',
    role: ''
  };

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getUserDetails();
  }

  getUserDetails() {
    this.authService.getAccountDetails(this.authService.getEmail()!).subscribe({
      next: (response: AccountDetails) => {
        if (!!response){
          this.user = {
            email: response.email,
            role: response.role
          };
        }
      },
      error: (error: any) => {
        console.log("Error getting user details");
      }
    });
  }

  logout(){
    this.authService.logout();
    window.location.href = '/account/login';
  }
}

class User {
  email!: string;
  role!: string;
}