<header>
  <nav class="navbar fixed-top navbar-expand-md navbar-dark walentik-dark-bg">
    <div class="container-fluid">
      <a [routerLink]="['/']" class="navbar-brand walentik-blue">Walentik Software</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarLinks" aria-controls="navbarLinks" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarLinks">
        <ul class="navbar-nav ms-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a class="nav-link active walentik-blue" aria-current="page" [routerLink]="['/']">Home</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle walentik-blue" href="#" id="pricingDropdown" data-bs-toggle="dropdown" aria-expanded="false">Pricing</a>
            <ul class="dropdown-menu" aria-labelledby="pricingDropdown">
              <li><a class="dropdown-item" [routerLink]="['/pricing']">Website Estimate</a></li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link walentik-blue" [routerLink]="['/frycards']">Fry Cards</a>
          </li>
          <li class="nav-item">
            <a class="nav-link walentik-blue" *ngIf="!authService.isAuthenticated()" [routerLink]="['/account/login']">Login</a>
            <a class="nav-link walentik-blue" *ngIf="authService.isAuthenticated()" [routerLink]="['/account/logout']">Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>