
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailService } from '../../../../services/email.service';

@Component({
  selector: 'app-frycards-support-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './frycards-support-form.component.html',
  styleUrls: ['./frycards-support-form.component.css']
})
export class FryCardsSupportFormComponent {
  loading = false;
  submitted = false;
  returnUrl: string = ''; 
  error: string = '';
  success: string = '';


  public form: FormGroup;
  public firstName: string = '';
  public lastName: string = '';
  public emailAddress: string = '';
  public details: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private emailService: EmailService,
  ){
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailAddress: ['', Validators.required],
      details: ''
    });
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.error = '';
    this.success = '';

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    let body = "Name: " + this.f['firstName'].value + " " + this.f['lastName'].value + "<br />"
    + "Email: " + this.f['emailAddress'].value + "<br />"
    + "Request: " + this.f['details'].value;
    
    this.sendEmail(["frycards@walentik.net"], "Support Requested for Fry Cards", body);
    this.sendEmail([this.f['emailAddress'].value], "Support Requested for Fry Cards", body);

    this.loading = false;

    setTimeout(() => {
      this.reload();
    }, 2000);
  }

  sendEmail(to: string[], subject: string, body: string) {
    this.emailService.sendEmail(to, subject, body).subscribe(
      response => {
        console.log(response);
    });
  }

  reload() {
    let currentUrl = this.router.url;
    this.router.navigate([currentUrl])
      .then(() => {
        window.location.reload();
      });
  }
}
