import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('services') services!: ElementRef;

  constructor(private titleService: Title) {
    this.services = {} as ElementRef;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Walentik Software LLC - Home'); // Set the page title
  }

  scrollToServices(): void {
    this.services.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
