<div *ngIf="error" class="alert alert-danger">{{error}}</div>
<div *ngIf="success" class="alert alert-success">{{success}}</div>
<h1 id="tableLabel">Support Information</h1>
<p>Having issues with the app?</p>
<button class="btn walentik-blue-bg walentik-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSupportForm"
        aria-expanded="false" aria-controls="collapseSupportForm">
  Submit a Support Request
</button>
<div class="collapse" id="collapseSupportForm">
  <div class="card card-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="mb-md-5 mt-md-4 pb-5">
        <h2 class="fw-bold mb-2 text-uppercase text-black">Request Support</h2>

        <p class="text-black mb-5">Please enter your information below!</p>

        <div class="form-outline form-white mb-4">
          <input type="text" formControlName="firstName" id="firstName" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f['firstName']['errors'] }" />
          <label class="form-label" for="firstName">First Name</label>
          <div *ngIf="submitted && f['firstName']['errors']" class="invalid-feedback">
            <div *ngIf="f['firstName']['errors']['required']">First Name is required</div>
          </div>
        </div>

        <div class="form-outline form-white mb-4">
          <input type="text" formControlName="lastName" id="lastName" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f['lastName']['errors'] }" />
          <label class="form-label" for="lastName">Last Name</label>
          <div *ngIf="submitted && f['lastName']['errors']" class="invalid-feedback">
            <div *ngIf="f['lastName']['errors']['required']">Last Name is required</div>
          </div>
        </div>

        <div class="form-outline form-white mb-4">
          <input type="text" formControlName="emailAddress" id="emailAddress" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f['emailAddress']['errors'] }" />
          <label class="form-label" for="emailAddress">Email Address</label>
          <div *ngIf="submitted && f['emailAddress']['errors']" class="invalid-feedback">
            <div *ngIf="f['emailAddress']['errors']['required']">Email Address is required</div>
          </div>
        </div>

        <div class="form-outline form-white mb-4">
          <input type="text" formControlName="details" id="details" class="form-control form-control-lg" />
          <label class="form-label" for="details">Details</label>
        </div>

        <button [disabled]="loading" class="btn walentik-blue-bg walentik-light btn-lg px-5" type="submit">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Submit a Request for Support
        </button>

      </div>
    </form>
  </div>
</div>
