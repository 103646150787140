<div *ngIf="error" class="alert alert-danger">{{error}}</div>
<div *ngIf="success" class="alert alert-success">{{success}}</div>
<h1 id="tableLabel">Feedback Information</h1>
<p>Have improvements for the app?</p>
<button class="btn walentik-blue-bg walentik-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFeedbackForm"
        aria-expanded="false" aria-controls="collapseFeedbackForm">
  Provide Feedback
</button>
<div class="collapse" id="collapseFeedbackForm">
  <div class="card card-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="mb-md-5 mt-md-4 pb-5">
        <h2 class="fw-bold mb-2 text-uppercase text-black">Provide Feedback</h2>

        <p class="text-black mb-5">Please enter your information below!</p>

        <div class="form-outline form-white mb-4">
          <input type="text" formControlName="feedbackFirstName" id="feedbackFirstName" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f['feedbackFirstName']['errors'] }" />
          <label class="form-label" for="feedbackFirstName">First Name</label>
          <div *ngIf="submitted && f['feedbackFirstName']['errors']" class="invalid-feedback">
            <div *ngIf="f['feedbackFirstName']['errors']['required']">First Name is required</div>
          </div>
        </div>

        <div class="form-outline form-white mb-4">
          <input type="text" formControlName="feedbackLastName" id="feedbackLastName" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f['feedbackLastName']['errors'] }" />
          <label class="form-label" for="feedbackLastName">Last Name</label>
          <div *ngIf="submitted && f['feedbackLastName']['errors']" class="invalid-feedback">
            <div *ngIf="f['feedbackLastName']['errors']['required']">Last Name is required</div>
          </div>
        </div>

        <div class="form-outline form-white mb-4">
          <input type="text" formControlName="feedbackEmailAddress" id="feedbackEmailAddress" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f['feedbackEmailAddress']['errors'] }" />
          <label class="form-label" for="feedbackEmailAddress">Email Address</label>
          <div *ngIf="submitted && f['feedbackEmailAddress']['errors']" class="invalid-feedback">
            <div *ngIf="f['feedbackEmailAddress']['errors']['required']">Email Address is required</div>
          </div>
        </div>

        <div class="form-outline form-white mb-4">
          <input type="text" formControlName="feedbackDetails" id="feedbackDetails" class="form-control form-control-lg" />
          <label class="form-label" for="feedbackDetails">Details</label>
        </div>

        <button [disabled]="loading" class="btn walentik-blue-bg walentik-light btn-lg px-5" type="submit">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Submit Feedback
        </button>

      </div>
    </form>
  </div>
</div>
