
<header class='blog-header'>
    <div class="container">
        <div class="row pb-3">
            <div class="col-md-12">
                <div style="display: flex; justify-content: center;">
                    <img [src]="blog.displayImage" class="blog-title-img mx-auto d-block img-fluid">
                </div>
            </div>
        </div>
        <div class="row py-3">
            <div class="col-md-12">
                <h1 class='text-center blog-post-title'><strong>{{blog.title}}</strong></h1>
            </div>
            <a *ngIf="isEditable" href="/blog/{{ blog.id }}/edit" class="btn walentik-blue-bg text-white">Edit</a>
        </div>
    </div>
</header>
<article class="blog-post">
    <div class="container py-5" [innerHTML]="blog.content">

    </div>
</article>
<section class="cta-section bg-light">
    <div class="container py-5" [innerHTML]="blog.callToAction">

    </div>
</section>