import { Component } from '@angular/core';
import * as moment from 'moment'
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  environmentName: string = environment.name;

  copyrightYearYYYY = moment().format('YYYY');

  constructor() 
  { 
    if (this.environmentName === 'Production') {
      this.environmentName = '';
    }
  }
}
