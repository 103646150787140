import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { NgIf } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {
  public message: string = "";
  public progress: number = 0;
  @Input() public isMultiFile: boolean = false;
  @Input() public fileDirectory: string = 'img/';
  @Output() public onUploadFinished = new EventEmitter();

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  uploadFiles = (event: Event) => {
    const { target } = event;
    let files = (target as HTMLInputElement).files;

    if (files == null || files?.length === 0) return;

    for (let i = 0; i < files.length; i++) {
      this.progress = 0;
      this.message = "";
      let fileToUpload = <File>files[i];
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append('fileDirectory', this.fileDirectory);

      this.UploadFileToApi(formData);
    }
  }

  private UploadFileToApi(formData: FormData) {
    this.http.post(environment.baseUrl + 'api/upload/', formData, { reportProgress: true, observe: 'events' })
      .subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            let progressTotal = event.total ?? 100;
            this.progress = Math.round(100 * event.loaded / progressTotal);
          }
          else if (event.type === HttpEventType.Response) {
            this.message = 'Upload success.';
            this.onUploadFinished.emit(event.body);
          }
        },
        error: (err: HttpErrorResponse) => console.log(err)
      });
  }
}
