import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient, public snackBar: MatSnackBar) { }

  openSuccessBar(message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.duration = 2000;
    config.panelClass = ['success-snackbar']; 
    this.snackBar.open(message, 'Success', config);
  }

  openErrorBar(message: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.duration = 2000;
    config.panelClass = ['error-snackbar'];
    this.snackBar.open(message, 'Error', config);
  }

  sendEmail(to:string[], subject:string, emailBody: string) : Observable<boolean> {
    // replace with your actual email sending logic

    const emailUrl = this.baseUrl + 'api/email/send';

    return this.http.post(emailUrl, { 
      to: to,
      subject: subject,
      body: emailBody, 
    }, { reportProgress: true, observe: 'events' })
      .pipe(
        map((event: any) => {
          if (event.type === HttpEventType.Response) {
            this.openSuccessBar("Email Sent");
            return true;
          }
          return false;
        }),
        catchError((err: any) => {
          this.openErrorBar("Email Not Sent");
          console.log(err);
          return of(false);
        })
      );
  }
}
