<div class="container">
    <div class="row py-3">
        <!-- Loop through blogs -->
        <div *ngFor="let blog of blogs" class="col-md-4">
            <div class="card mb-4">
                <img src="{{ blog.displayImage }}" class="card-img-top" alt="Blog Image">
                <div class="card-body">
                    <h1 class="card-title text-center">{{ blog.title }}</h1>
                    <p class="card-text">{{ blog.description }}</p>
                    <div class="text-center">
                        <a href="/blog/{{ blog.id }}" class="btn walentik-blue-bg text-white">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button *ngIf="isEditable" mat-fab color="primary" class="add-blog" routerLink="/blog/new">
        <mat-icon>add</mat-icon>
    </button>
</div>