<!-- Page Header-->
<header class="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
    <div class="page-header-ui-content pt-3">
      <div class="container px-5 text-center">
        <div class="row gx-5 justify-content-center">
          <div class="col-lg-8">
            <h1 class="page-header-ui-title mb-3">Fry Cards App</h1>
            <p class="page-header-ui-text">
              1000 Words Required to Read
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="svg-border-rounded text-white">
      <!-- Rounded SVG Border-->
      <svg style="margin-bottom: -1px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
    </div>
  </header>
  <section class="bg-white py-5">
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-12 pb-5">
          <h1>App Information</h1>
          <p>
            Helping teachers manage their student's basic reading skills throughout the year to monitor the words that are known and unknown.
            Fry words are basic words from the English dictionary that are most common words used in reading.
            This app is perfect for classroom or personal use. This is a great app to track known and unknown fry words.
            Teachers can use this for each student and have as many accounts as needed or can use this for just one person.
            This app has many different features to use it. This app is designed by a Special Education Teacher who specializes in teaching
            students how to read.
          </p>
        </div>
  
        <div class="col-md-6 pb-5">
          <h1>Apple</h1>
          <a href="https://apps.apple.com/us/app/fry-cards/id6450242480">
            <img class="img-fluid" src="assets\img\downloadOnAppleAppStore.png" />
          </a>
        </div>
  
        <div class="col-md-6 pb-5">
          <h1>Android</h1>
          <a href="https://play.google.com/store/apps/details?id=com.walentik.frycardsapp">
            <img class="img-fluid" src="assets\img\downloadOnAndroidAppStore.png" />
          </a>
        </div>
      </div>
    </div>
  
    <div class="container">
      <div class="row">
        <div class="col-md-6 pb-5">
          <app-frycards-support-form></app-frycards-support-form>
        </div>
        <div class="col-md-6 pb-5">
          <app-frycards-feedback-form></app-frycards-feedback-form>
        </div>
      </div>
    </div>

  </section>
  