<!-- Hero section -->
<section id="hero" class="bg-white py-10">
    <div class="container py-5">
        <div class="row align-items-center">
            <div class="col-md-6">
                <h1 class="display-4">We create amazing software solutions for your business</h1>
                <p class="lead">We are passionate and skilled developers who can help you with any software project, from web and mobile applications to desktop and cloud software. We use the latest technologies and best practices to deliver high-quality and reliable products that meet your needs and expectations.</p>
                <a class="btn walentik-light walentik-blue-bg btn-lg mb-4" (click)="scrollToServices()" role="button">Get Started</a>
            </div>
            <div class="col-md-6">
                <img src="https://source.unsplash.com/800x600/?software,technology" alt="Software image" class="img-fluid">
            </div>
        </div>
    </div>
</section>


<!-- Services section -->
<section id="services" #services class="bg-light py-5">
    <div class="container py-4">
        <h1 class="text-center">Our Services</h1>
        <div class="row">
            <div class="col-md-4 mb-4">
                <div class="card walentik-light-bg walentik-blue text-center">
                    <div class="card-body">
                        <i class="bi bi-code-slash" style="font-size: 48px;"></i>
                        <h2 class="card-title walentik-blue">Custom Development</h2>
                        <p class="card-text">We can develop any software solution tailored to your specific requirements and preferences. We use agile methodologies and follow the best coding standards to ensure quality and efficiency.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4">
                <div class="card walentik-light-bg walentik-blue text-center">
                    <div class="card-body">
                        <i class="bi bi-phone" style="font-size: 48px;"></i>
                        <h2 class="card-title walentik-blue">Mobile Apps</h2>
                        <p class="card-text">We can create stunning and functional mobile apps for both iOS and Android platforms. We use native or cross-platform frameworks to deliver optimal performance and user experience.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4">
                <div class="card walentik-light-bg walentik-blue text-center">
                    <div class="card-body">
                        <i class="bi bi-cloud" style="font-size: 48px;"></i>
                        <h2 class="card-title walentik-blue">Cloud Services</h2>
                        <p class="card-text">We can help you leverage the power and scalability of the cloud for your software projects. We can design, deploy, and manage cloud-based solutions using the most popular providers such as AWS, Azure, or Google Cloud.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="why-walentik-software" class="py-5 walentik-dark-bg">
    <div class="container">
        <h1 class="walentik-light">Why Walentik Software?</h1>
        <h3 class="opacity-50 walentik-light">Your trusted partner for software development</h3> 
        
        <div class="row">
            <div class="col-md-8 mx-auto">
                <div class="card walentik-blue-bg walentik-light mb-4">
                    <div class="card-body">
                        <h2 class="card-title walentik-light"><i class="bi bi-lightbulb"></i> Expertise and Experience</h2>
                        <p class="card-text">With years of experience, we have the expertise to handle any software project. We stay up-to-date with the latest technologies and best practices to deliver high-quality solutions.</p>
                    </div>
                </div>
                <div class="card walentik-blue-bg walentik-light mb-4">
                    <div class="card-body">
                        <h2 class="card-title walentik-light"><i class="bi bi-puzzle"></i> Customized Solutions</h2>
                        <p class="card-text">We understand that every business is unique. That's why we offer customized software solutions tailored to your specific requirements and preferences. We work closely with you to ensure that the final product meets your needs and exceeds your expectations.</p>
                    </div>
                </div>
                <div class="card walentik-blue-bg walentik-light mb-4">
                    <div class="card-body">
                        <h2 class="card-title walentik-light"><i class="bi bi-link-45deg"></i> Seamless Integration</h2>
                        <p class="card-text">We ensure seamless integration of our software solutions with your existing systems and processes. Whether it's integrating with third-party APIs or migrating data from legacy systems, we make sure that the transition is smooth and hassle-free.</p>
                    </div>
                </div>
                <div class="card walentik-blue-bg walentik-light">
                    <div class="card-body">
                        <h2 class="card-title walentik-light"><i class="bi bi-headset"></i> Reliable Support</h2>
                        <p class="card-text">Our commitment to customer satisfaction doesn't end with the delivery of the software. We provide reliable support and maintenance services to ensure that your software continues to perform optimally and meets your evolving business needs.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="works" class="bg-light py-5">
    <!-- Our works section -->
    <div class="container py-4">
        <h1 class="text-center">Our Works</h1>
        <h3 class="text-center">Here are some of the projects that we have completed for our clients.</h3>
        <div class="row row-cols-1 row-cols-md-3 g-4 align-items-start">
            <!-- Project 1 -->
            <div class="col">
                <div class="card h-100">
                    <img style="padding: 25px;" src="assets\img\GlobalNetLogistics_Logo.png" alt="GlobalNet Logistics Project image" class="card-img-top">
                    <div class="card-body">
                        <h2 class="card-title">GlobalNet Logistics</h2>
                        <p class="card-text">We designed and developed a responsive and modern website for a local business using Angular. The website showcases their products and services, and has a contact form and a testimonial section.</p>
                    </div>
                    <div class="card-footer">
                        <a href="https://www.globalnetlogistics.biz" class="btn walentik-light walentik-blue-bg">View Project</a>
                    </div>
                </div>
            </div>
            <!-- Project 2 -->
            <div class="col">
                <div class="card h-100">
                    <img src="assets\img\fryCardsAppIcon.jpg" alt="Fry Cards App image" class="card-img-top">
                    <div class="card-body">
                        <h2 class="card-title">Fry Cards</h2>
                        <p class="card-text">We created a native mobile app for iOS and Android platforms that helps users learn to read basic English using the Fry words. The app has a user-friendly interface, an assessment mode for teachers, and supports multiple users for families and classroms.</p>
                    </div>
                    <div class="card-footer">
                        <a [routerLink]="['/frycards']" class="btn walentik-light walentik-blue-bg">View Project</a>
                    </div>
                </div>
            </div> 
            <!-- Project 3 -->
            <!-- <div class="col">
                <div class="card h-100">
                    <img src="https://source.unsplash.com/300x200/?website,design" alt="Project 3 image" class="card-img-top">
                    <div class="card-body">
                        <h5 class="card-title">Coming Soon</h5>
                        <p class="card-text">We are developed a hybrid solution</p>
                    </div>
                    <div class="card-footer">
                        <a href="#" class="btn btn-primary">View Project</a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>


