<!-- Page Title
============================================= -->
<section class="page-title bg-transparent">
    <div class="container">
        <div class="page-title-row">

            <div class="page-title-content">
                <h1>Website Estimate</h1>
            </div>

            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Website Estimate</li>
                </ol>
            </nav>

        </div>
    </div>
</section><!-- .page-title end -->

<!-- Content
============================================= -->
<section id="content" class="pb-5">
    <div class="content-wrap">
        <form (submit)="onSubmit()" id="website-cost" method="post" enctype="multipart/form-data">
            <div class="container">

                <div class="row">
                    <div class="col-lg-8 order-1">
                        <div class="row form-widget">
                            <div class="form-result"></div>
                                <div class="form-process">
                                    <div class="css3-spinner">
                                        <div class="css3-spinner-scaler"></div>
                                    </div>
                                </div>
                                <div class="col-md-6 form-group mb-4">
                                    <label class="mb-3">Responsive Design:</label><br>
                                    <div class="btn-group">
                                        <input (change)="updateResponsiveDesignCost($event)" type="radio" class="btn-check required" name="website-cost-responsive" id="website-cost-responsive-no" checked autocomplete="off" value="Desktop">
                                        <label for="website-cost-responsive-no" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">Desktop</label>
                                        <input (change)="updateResponsiveDesignCost($event)" type="radio" class="btn-check required" name="website-cost-responsive" id="website-cost-responsive-yes" autocomplete="off" value="Desktop + Mobile">
                                        <label for="website-cost-responsive-yes" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">Desktop &amp; Mobile</label>
                                    </div>
                                </div>

                                <div class="col-md-6 form-group mb-4">
                                    <label class="mb-3">Retina Design:</label><br>
                                    <div class="btn-group">
                                        <input (change)="updateRetinaDesignCost($event)" type="radio" class="btn-check required" name="website-cost-retina" id="website-cost-retina-yes" autocomplete="off" value="Yes Please!">
                                        <label for="website-cost-retina-yes" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">Yes Please!</label>
                                        <input (change)="updateRetinaDesignCost($event)" type="radio" class="btn-check required" name="website-cost-retina" id="website-cost-retina-no" checked autocomplete="off" value="No Thanks!">
                                        <label for="website-cost-retina-no" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">No Thanks!</label>
                                    </div>
                                </div>

                                <div class="col-12 form-group mb-4">
                                    <label for="website-cost-type" class="mb-3">Website Type:</label><br>
                                    <div class="btn-group flex-wrap">
                                        <input (change)="updateWebsiteTypeCost($event)" type="radio" class="btn-check required" name="website-cost-type" id="website-cost-type-corporate" autocomplete="off" value="Corporate">
                                        <label for="website-cost-type-corporate" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">Corporate</label>
                                        <input (change)="updateWebsiteTypeCost($event)" type="radio" class="btn-check required" name="website-cost-type" id="website-cost-type-ecommerce" autocomplete="off" value="eCommerce">
                                        <label for="website-cost-type-ecommerce" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">eCommerce</label>
                                        <input (change)="updateWebsiteTypeCost($event)" type="radio" class="btn-check required" name="website-cost-type" id="website-cost-type-blog" autocomplete="off" value="Blog">
                                        <label for="website-cost-type-blog" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">Blog</label>
                                        <input (change)="updateWebsiteTypeCost($event)" type="radio" class="btn-check required" name="website-cost-type" id="website-cost-type-personal" autocomplete="off" value="Personal">
                                        <label for="website-cost-type-personal" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">Personal</label>
                                        <input (change)="updateWebsiteTypeCost($event)" type="radio" class="btn-check required" name="website-cost-type" id="website-cost-type-database" autocomplete="off" value="Database">
                                        <label for="website-cost-type-database" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">Database</label>
                                    </div>
                                </div>

                                <div class="col-12 form-group mb-4">
                                    <label for="website-cost-design" class="mb-3">Style of Design:</label><br>
                                    <div class="btn-group flex-wrap">
                                        <input (change)="updateStyleTypeCost($event)" type="radio" class="btn-check required" name="website-cost-design" id="website-cost-design-basic" autocomplete="off" checked autocomplete="off" value="Basic">
                                        <label for="website-cost-design-basic" class="btn btn-outline-secondary px-5 fw-semibold ls-0 text-transform-none">Basic</label>
                                        <input (change)="updateStyleTypeCost($event)" type="radio" class="btn-check required" name="website-cost-design" id="website-cost-design-flat" autocomplete="off" value="Flat">
                                        <label for="website-cost-design-flat" class="btn btn-outline-secondary px-5 fw-semibold ls-0 text-transform-none">Flat</label>
                                        <input (change)="updateStyleTypeCost($event)" type="radio" class="btn-check required" name="website-cost-design" id="website-cost-design-modern" autocomplete="off" value="Modern">
                                        <label for="website-cost-design-modern" class="btn btn-outline-secondary px-5 fw-semibold ls-0 text-transform-none">Modern</label>
                                    </div>
                                </div>
                                
                                <div class="col-8 form-group mb-4">
                                    <label for="website-cost-pages" class="mb-3">Number of Pages:</label><br>
                                    <input (change)="updateNumOfPagesCost()" [(ngModel)]="numOfWebPages" id="website-cost-pages" name="website-cost-pages" class="website-pages required input-range-slider w-100" type="range" min="10" max="100" step="10">
                                    <span id="website-cost-pages-value">{{numOfWebPages}}</span>
                                </div>
                                
                                <div class="col-8 form-group mb-4">
                                    <label for="website-cost-pages" class="mb-3">Number of Forms: (Contact us, Request a quote, etc.)</label><br>
                                    <input (change)="updateNumOfFormPagesCost()" [(ngModel)]="numOfFormPages" id="website-cost-pages" name="website-cost-pages" class="website-pages required input-range-slider w-100" type="range" min="0" max="20" step="1">
                                    <span id="website-cost-pages-value">{{numOfFormPages}}</span>
                                </div>

                                <div class="col-12 form-group mb-4">
                                    <label for="website-cost-cms" class="mb-3">CMS:</label><br>
                                    <div class="btn-group flex-wrap">
                                        <input (change)="updateCMSTypeCost($event)" type="radio" class="btn-check required" name="website-cost-cms" id="website-cost-cms-none" autocomplete="off" data-price="0" value="None">
                                        <label for="website-cost-cms-none" class="btn btn-outline-secondary px-4 fw-semibold ls-0 text-transform-none">None</label>
                                        <input (change)="updateCMSTypeCost($event)" type="radio" class="btn-check required" name="website-cost-cms" id="website-cost-cms-wordpress" autocomplete="off" data-price="1200" value="Wordpress">
                                        <label for="website-cost-cms-wordpress" class="btn btn-outline-secondary px-4 fw-semibold ls-0 text-transform-none"><i class="fa-brands fa-wordpress-simple"></i> Wordpress</label>
                                        <input (change)="updateCMSTypeCost($event)" type="radio" class="btn-check required" name="website-cost-cms" id="website-cost-cms-headless" autocomplete="off" data-price="2000" value="Headless API">
                                        <label for="website-cost-cms-headless" class="btn btn-outline-secondary px-4 fw-semibold ls-0 text-transform-none">Headless API</label>
                                    </div>
                                </div>

                                <div class="col-8 form-group mb-4">
                                    <label for="website-cost-seo" class="mb-3">SEO Placement:</label><br>
                                    <input (change)="updateNumOfSEOCost()" [(ngModel)]="numOfSEOPlacements" id="website-cost-seo" name="website-cost-seo" class="website-seo required input-range-slider w-100" type="range" min="0" max="210" step="30">
                                    <span id="website-cost-pages-value">{{numOfSEOPlacements}}</span>
                                </div>

                                <div class="col-md-6 form-group mb-4">
                                    <label class="mb-3">Google Analytics Implementation:</label><br>
                                    <div class="btn-group">
                                        <input (change)="updateGoogleAnalyticsCost($event)" type="radio" class="btn-check required" name="website-cost-google-analytics" id="website-cost-google-analytics-yes" autocomplete="off" value="Yes">
                                        <label for="website-cost-google-analytics-yes" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">Yes</label>
                                        <input (change)="updateGoogleAnalyticsCost($event)" type="radio" class="btn-check required" name="website-cost-google-analytics" id="website-cost-google-analytics-no" checked autocomplete="off" value="No">
                                        <label for="website-cost-google-analytics-no" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">No</label>
                                    </div>
                                </div>

                                <div class="col-md-6 form-group mb-4">
                                    <label class="mb-3">Do you own your domain name?: (Yearly cost)</label><br>
                                    <div class="btn-group">
                                        <input (change)="updateDomainNameCost($event)" type="radio" class="btn-check required" name="website-cost-domain-name" id="website-cost-domain-name-yes" checked autocomplete="off" value="Yes">
                                        <label for="website-cost-domain-name-yes" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">Yes</label>
                                        <input (change)="updateDomainNameCost($event)" type="radio" class="btn-check required" name="website-cost-domain-name" id="website-cost-domain-name-no" autocomplete="off" value="No">
                                        <label for="website-cost-domain-name-no" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">No</label>
                                    </div>
                                </div>

                                <div class="col-md-6 form-group mb-4">
                                    <label class="mb-3">Do you need web hosting?: (Yearly cost)</label><br>
                                    <div class="btn-group">
                                        <input (change)="updateWebHostingCost($event)" type="radio" class="btn-check required" name="website-cost-web-hosting" id="website-cost-web-hosting-yes" autocomplete="off" value="Yes">
                                        <label for="website-cost-web-hosting-yes" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">Yes</label>
                                        <input (change)="updateWebHostingCost($event)" type="radio" class="btn-check required" name="website-cost-web-hosting" id="website-cost-web-hosting-no" checked autocomplete="off" value="No">
                                        <label for="website-cost-web-hosting-no" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">No</label>
                                    </div>
                                </div>

                                <div class="col-md-6 form-group mb-4">
                                    <label class="mb-3">Before 30 Days:</label><br>
                                    <div class="btn-group">
                                        <input (change)="updateRushCost($event)" type="radio" class="btn-check required" name="website-cost-before-30-days" id="website-cost-before-30-days-yes" autocomplete="off" value="Yes">
                                        <label for="website-cost-before-30-days-yes" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">Yes! It's Urgent</label>
                                        <input (change)="updateRushCost($event)" type="radio" class="btn-check required" name="website-cost-before-30-days" id="website-cost-before-30-days-no" checked autocomplete="off" value="No! Take Your Time">
                                        <label for="website-cost-before-30-days-no" class="btn btn-outline-secondary px-3 fw-semibold ls-0 text-transform-none">No! Take Your Time</label>
                                    </div>
                                </div>

                                <div class="col-md-6 form-group mb-4">
                                    <label for="website-cost-name">Name:</label>
                                    <input [(ngModel)]="name" type="text" name="website-cost-name" id="website-cost-name" class="form-control required" value="" placeholder="Enter your Full Name">
                                </div>
                                <div class="col-md-6 form-group mb-4">
                                    <label for="website-cost-contact">Contact:</label>
                                    <input [(ngModel)]="phone" type="text" name="website-cost-contact" id="website-cost-contact" class="form-control required" value="" placeholder="Enter your Contact Number">
                                </div>
                                <div class="col-12 form-group mb-4">
                                    <label for="website-cost-email">Email:</label>
                                    <input [(ngModel)]="email" type="email" name="website-cost-email" id="website-cost-email" class="form-control required" value="" placeholder="Enter your Email">
                                </div>

                                <div class="col-12 form-group mb-4">
                                    <label for="website-cost-message">Additional Message (Optional):</label>
                                    <textarea [(ngModel)]="message" name="website-cost-message" id="website-cost-message" class="form-control" rows="3" placeholder="Enter your message"></textarea>
                                </div>

                                <div class="col-12 d-none">
                                    <input type="text" id="website-cost-botcheck" name="website-cost-botcheck" value="">
                                </div>

                                <input type="hidden" name="prefix" value="website-cost-">
                                <input type="hidden" name="subject" value="Website Cost Estimate Request">
                                <input type="hidden" name="html_title" value="Website Cost Estimation">
                                <input type="hidden" id="website-cost-price" name="website-cost-total-price" value="">
                        </div>
                    </div>

                    <div class="col-lg-4 order-2 mb-4">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="w-50">Items:</th>
                                        <th class="w-50">Price:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Responsive Design:</td>
                                        <td class="responsive-value fw-semibold">${{responsiveDesignCost}}</td>
                                    </tr>
                                    <tr>
                                        <td>Retina Design:</td>
                                        <td class="retina-value fw-semibold">${{retinaDesignCost}}</td>
                                    </tr>
                                    <tr>
                                        <td>Website Type:</td>
                                        <td class="website-type-value fw-semibold">${{websiteTypeCost}}</td>
                                    </tr>
                                    <tr>
                                        <td>Style of Design:</td>
                                        <td class="design-value fw-semibold">${{styleTypeCost}}</td>
                                    </tr>
                                    <tr>
                                        <td># of Pages:</td>
                                        <td class="pages-value fw-semibold">${{numOfWebPagesCost}}</td>
                                    </tr>
                                    <tr>
                                        <td># of Forms:</td>
                                        <td class="pages-value fw-semibold">${{numOfFormPagesCost}}</td>
                                    </tr>
                                    <tr>
                                        <td>CMS:</td>
                                        <td class="cms-value fw-semibold">${{CMSTypeCost}}</td>
                                    </tr>
                                    <tr>
                                        <td>SEO Keywords:</td>
                                        <td class="seo-value fw-semibold">${{NumOfSEOCost}}</td>
                                    </tr>
                                    <tr>
                                        <td>Google Analytics:</td>
                                        <td class="google-analytics-value fw-semibold">${{GoogleAnalyticsCost}}</td>
                                    </tr>
                                    <tr>
                                        <td>Domain Name:</td>
                                        <td class="domain-name-value fw-semibold">${{DomainNameCost}}/year</td>
                                    </tr>
                                    <tr>
                                        <td>Web Hosting:</td>
                                        <td class="web-hosting-value fw-semibold">${{WebHostingCost}}/year</td>
                                    </tr>
                                    <tr>
                                        <td>Before 30 Days:</td>
                                        <td class="urgent-value fw-semibold">${{RushCost}}</td>
                                    </tr>
                                    <tr>
                                        <th class="fw-semibold h5 mb-0">Total</th>
                                        <th class="total-price h5 fw-semibold mb-0">${{totalDesignCost}}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-12 order-3">
                        <button type="submit" name="website-cost-submit" class="btn btn-success btn-lg">Confirm</button>
                    </div>
                </div>

                
            </div>
        </form>
    </div>
</section><!-- #content end -->