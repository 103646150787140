import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { BlogService, Blog } from 'src/app/services/blog.service';
import { Title } from '@angular/platform-browser';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-blog-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditBlogComponent implements OnInit {
  errorText: string = null!;
  blogId = -1; // Initializes with -1, or fetch to edit existing blog
  blog!: Blog;

  constructor(
    private route: ActivatedRoute,
    private blogService: BlogService,
    private titleService: Title,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    if(!this.authService.isAdministrator()){
      window.location.href = '/account/login';
    }

    this.route.paramMap.subscribe(params => {
      this.blogId = Number(params.get('id'));
      this.blogService.getBlog(this.blogId).subscribe(blog => {
        this.blog = blog;
        this.titleService.setTitle('Walentik Software LLC - Edit Blog - ' + blog.title); // Set the page title
      });
    });
  }

  saveBlog(){
    this.blogService.updateBlog(this.blogId, this.blog).subscribe({
      next: (response: any) => {
        console.log(response);
        if (!!response) {
          if (!!response.error) {
            this.errorText = response.error.title + ": " + response.error.detail;
            console.log(this.errorText);
          }
          else {
            console.log("Successful Update");
            window.location.href = '/blog/' + this.blogId;
          } 
        }
      },
      error: (error: any) => {
        console.log("Error during update blog");
      }
    });
  }
  
  onNewUploadAddFile(event: any) {
    this.blog.displayImage = event.fullPath;
  }

  deactivateBlog(){
    this.blogService.deleteBlog(this.blogId).subscribe({
      next: (response: any) => {
        console.log(response);
        if (!!response) {
          if (!!response.error) {
            this.errorText = response.error.title + ": " + response.error.detail;
            console.log(this.errorText);
          }
          else {
            console.log("Successful Delete");
            window.location.href = '/blog';
          } 
        }
      },
      error: (error: any) => {
        console.log("Error during delete blog");
      }
    });
  }

  reactivateBlog(){
    this.blogService.reactivateBlog(this.blogId).subscribe({
      next: (response: any) => {
        console.log(response);
        if (!!response) {
          if (!!response.error) {
            this.errorText = response.error.title + ": " + response.error.detail;
            console.log(this.errorText);
          }
          else {
            console.log("Successful Reactivataion");
            window.location.href = '/blog/' + this.blogId;
          } 
        }
      },
      error: (error: any) => {
        console.log("Error during reactivation of blog");
      }
    });
  }

}
