

<form class="container py-5">
    <mat-toolbar color="primary">
        <span>Edit Blog - {{ blog.title }}</span>
    </mat-toolbar>
    <mat-card>
        <mat-card-content>
                <mat-form-field class="w-100 my-2" appearance="fill">
                    <mat-label>Title</mat-label>
                    <input matInput type="text" name="blogTitle" [(ngModel)]="blog.title">
                </mat-form-field>

                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Description</mat-label>
                    <input matInput [(ngModel)]="blog.description" name="description">
                </mat-form-field>
    
                <mat-label>Default Image</mat-label>
                <app-file-uploader (onUploadFinished)="onNewUploadAddFile($event)" [isMultiFile]="false" [fileDirectory]="'img/blog/'" #fileUploadComponent1></app-file-uploader>
    
                <mat-form-field class="w-100 h-auto my-2" appearance="fill">
                    <mat-label>Default Image</mat-label>
                    <input matInput name="blogDisplayImage" [(ngModel)]="blog.displayImage" readonly>
                </mat-form-field>

                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Author</mat-label>
                    <input matInput [(ngModel)]="blog.author" name="author">
                </mat-form-field>

                <mat-label>Content</mat-label>
                <div>
                    <quill-editor class="w-100" name="blogContent" [(ngModel)]="blog.content"></quill-editor>
                </div>

                <mat-form-field class="w-100 h-auto my-2" appearance="fill">
                    <mat-label>Content HTML</mat-label>
                    <textarea matInput name="blogContentHtml" [(ngModel)]="blog.content"></textarea>
                </mat-form-field>

                <mat-label>Call To Action</mat-label>
                <div>
                    <quill-editor class="w-100" name="blogCallToAction" [(ngModel)]="blog.callToAction"></quill-editor>
                </div>

                <mat-form-field class="w-100 h-auto my-2" appearance="fill">
                    <mat-label>Call to Action HTML</mat-label>
                    <textarea matInput name="blogCallToActionHtml" [(ngModel)]="blog.callToAction"></textarea>
                </mat-form-field>
                    
                <!-- Add more user details here -->
                <button mat-raised-button color="primary" type="submit" (click)="saveBlog()">Save</button>
                <button mat-raised-button color="warn" type="submit" (click)="deactivateBlog()" class="ms-2" *ngIf="blog.active">Deactivate</button>
                <button mat-raised-button color="accent" type="submit" (click)="reactivateBlog()" class="ms-2" *ngIf="!blog.active">Reactivate</button>

                <h1>Preview</h1>

                <article class="blog-post">
                    <div class="container py-5" [innerHTML]="blog.content">
                
                    </div>
                </article>
                <section class="cta-section bg-light">
                    <div class="container py-5" [innerHTML]="blog.callToAction">
                
                    </div>
                </section>
        </mat-card-content>
    </mat-card>
</form>



