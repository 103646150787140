import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  
    constructor(
      private titleService: Title
    ) { }
  
    ngOnInit(): void {
      this.titleService.setTitle('Walentik Software LLC - Privacy Policy'); // Set the page title
    }

}
