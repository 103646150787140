
<div class="row" style="margin-bottom:15px;">
    <div class="col-md-3">
      <input type="file" #filepicker placeholder="Choose file" (change)="uploadFiles($event)" style="display:none;" [multiple]="isMultiFile">
      <button type="button" class="btn btn-success" (click)="filepicker.click()">Upload File</button>
    </div>
    <div class="col-md-4">
      <span class="upload" *ngIf="progress > 0">
        {{progress}}%
      </span>
      <span class="upload" *ngIf="message">
        {{message}}
      </span>
    </div>
  </div>
  