



<section class="bg-white py-10">
    <div class="container px-5">
        <div class="row gx-5 justify-content-center">
            <div class="col-lg-10 mt-5">

                <p style='margin-top:0in;margin-right:0in;margin-bottom:4.0pt;margin-left:0in;font-size:37px;font-family:"Aptos Display",sans-serif;'>Terms and Conditions</p>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:14.0pt;font-family:"Aptos",sans-serif;color:#595959;'>Walentik Software LLC</p>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:14.0pt;font-family:"Aptos",sans-serif;color:#595959;'>Last updated: February 17, 2024</p>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>Please read these terms and conditions carefully before using our website. By accessing or using our website, you agree to be bound by these terms and conditions and our privacy policy. If you do not agree to these terms and conditions, you must not use our website.</p>
                <h1 style='margin-top:.25in;margin-right:0in;margin-bottom:4.0pt;margin-left:0in;font-size:27px;font-family:"Aptos Display",sans-serif;color:#0F4761;font-weight:normal;'>1. Introduction</h1>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>These terms and conditions apply to your use of our website, www.walentik.net, and any services or products that we provide through our website. Our website is owned and operated by Walentik Software LLC, a limited liability company registered in Ohio, with its principal place of business at 6837 Revere Road, Parma Heights, Ohio 44130.</p>
                <h1 style='margin-top:.25in;margin-right:0in;margin-bottom:4.0pt;margin-left:0in;font-size:27px;font-family:"Aptos Display",sans-serif;color:#0F4761;font-weight:normal;'>2. License to use our website</h1>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>We grant you a non-exclusive, non-transferable, revocable, and limited license to access and use our website for your own personal and non-commercial purposes, subject to these terms and conditions and our privacy policy. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from our website, unless expressly authorized by us in writing.</p>
                <h1 style='margin-top:.25in;margin-right:0in;margin-bottom:4.0pt;margin-left:0in;font-size:27px;font-family:"Aptos Display",sans-serif;color:#0F4761;font-weight:normal;'>3. Acceptable use</h1>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>You agree to use our website in a lawful, ethical, and respectful manner. You may not use our website for any purpose that is illegal, fraudulent, harmful, offensive, or infringes the rights of others. You may not use our website to transmit or store any material that contains viruses, malware, spyware, or other harmful or malicious code. You may not use our website to interfere with or disrupt the normal operation of our website, our servers, or our network. You may not use our website to impersonate any person or entity, or to misrepresent your affiliation with any person or entity. You may not use our website to collect or harvest any personal or confidential information from other users, or to send unsolicited or unauthorized messages, advertisements, or promotions to other users. You may not use our website to violate any applicable laws, regulations, or codes of conduct.</p>
                <h1 style='margin-top:.25in;margin-right:0in;margin-bottom:4.0pt;margin-left:0in;font-size:27px;font-family:"Aptos Display",sans-serif;color:#0F4761;font-weight:normal;'>4. User accounts and passwords</h1>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>Some features or services of our website may require you to register an account with us. You are responsible for maintaining the confidentiality and security of your account and password, and for all activities that occur under your account. You agree to notify us immediately of any unauthorized access or use of your account or password, or any other breach of security. You agree to provide us with accurate, complete, and current information when you register or update your account. You agree not to use another user&apos;s account without their permission. We reserve the right to suspend or terminate your account at any time, for any reason, without notice or liability to you.</p>
                <h1 style='margin-top:.25in;margin-right:0in;margin-bottom:4.0pt;margin-left:0in;font-size:27px;font-family:"Aptos Display",sans-serif;color:#0F4761;font-weight:normal;'>5. Intellectual property rights</h1>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>We own or have the right to use all the intellectual property rights in our website, and in the content, software, products, and services that we provide through our website. These intellectual property rights include, but are not limited to, trademarks, trade names, logos, designs, copyrights, patents, trade secrets, and any other proprietary rights. You acknowledge and agree that you do not acquire any ownership or license rights in or to any of these intellectual property rights by accessing or using our website, or by purchasing or using any of our products or services.</p>
                <h1 style='margin-top:.25in;margin-right:0in;margin-bottom:4.0pt;margin-left:0in;font-size:27px;font-family:"Aptos Display",sans-serif;color:#0F4761;font-weight:normal;'>6. Disclaimer of warranties</h1>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>Our website, and the content, software, products, and services that we provide through our website, are provided on an &quot;as is&quot; and &quot;as available&quot; basis, without any warranties of any kind, either express or implied. To the fullest extent permitted by law, we disclaim all warranties, including, but not limited to, the warranties of merchantability, fitness for a particular purpose, non-infringement, and title. We do not warrant that our website, or the content, software, products, or services that we provide through our website, will be uninterrupted, error-free, accurate, complete, reliable, secure, or free from viruses or other harmful components. We do not warrant that any defects or errors will be corrected, or that your use of our website, or the content, software, products, or services that we provide through our website, will meet your expectations or requirements. You assume the entire risk and responsibility for your use of our website, and the content, software, products, and services that we provide through our website.</p>
                <h1 style='margin-top:.25in;margin-right:0in;margin-bottom:4.0pt;margin-left:0in;font-size:27px;font-family:"Aptos Display",sans-serif;color:#0F4761;font-weight:normal;'>7. Limitation of liability</h1>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>To the fullest extent permitted by law, we and our affiliates, directors, officers, employees, agents, contractors, licensors, and suppliers, will not be liable for any direct, indirect, incidental, consequential, special, exemplary, or punitive damages, or any other damages of any kind, arising out of or in connection with your use of our website, or the content, software, products, or services that we provide through our website, or these terms and conditions, even if we have been advised of the possibility of such damages. These damages include, but are not limited to, damages for loss of profits, revenue, data, goodwill, or other intangible losses. In no event will our total liability to you for all damages, losses, and causes of action, whether in contract, tort, or otherwise, exceed the amount paid by you, if any, for accessing or using our website, or for purchasing or using any of our products or services, in the 12 months preceding the event giving rise to the claim.</p>
                <h1 style='margin-top:.25in;margin-right:0in;margin-bottom:4.0pt;margin-left:0in;font-size:27px;font-family:"Aptos Display",sans-serif;color:#0F4761;font-weight:normal;'>8. Indemnification</h1>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>You agree to indemnify, defend, and hold harmless us and our affiliates, directors, officers, employees, agents, contractors, licensors, and suppliers, from and against any and all claims, liabilities, damages, losses, costs, expenses, and fees, including reasonable attorneys&apos; fees, arising out of or in connection with your use of our website, or the content, software, products, or services that we provide through our website, or your violation of these terms and conditions, or any applicable laws, regulations, or codes of conduct, or the rights of any third party.</p>
                <h1 style='margin-top:.25in;margin-right:0in;margin-bottom:4.0pt;margin-left:0in;font-size:27px;font-family:"Aptos Display",sans-serif;color:#0F4761;font-weight:normal;'>9. Modification of terms and conditions</h1>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>We reserve the right to modify these terms and conditions at any time, without prior notice to you. Any changes will be effective immediately upon posting on our website. Your continued use of our website after any such changes constitutes your acceptance of the new terms and conditions. You should review these terms and conditions periodically to ensure that you are aware of any changes. If you do not agree to any of the modified terms and conditions, you must stop using our website.</p>
                <h1 style='margin-top:.25in;margin-right:0in;margin-bottom:4.0pt;margin-left:0in;font-size:27px;font-family:"Aptos Display",sans-serif;color:#0F4761;font-weight:normal;'>10. Governing law and jurisdiction</h1>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>These terms and conditions, and any dispute or claim arising out of or in connection with them, or their subject matter or formation, will be governed by and construed in accordance with the laws of the State of Ohio, without regard to its conflict of laws principles. You agree to submit to the exclusive jurisdiction of the courts of the State of Ohio, or, if applicable, the federal courts of the United States, for the resolution of any such dispute or claim.</p>
                <h1 style='margin-top:.25in;margin-right:0in;margin-bottom:4.0pt;margin-left:0in;font-size:27px;font-family:"Aptos Display",sans-serif;color:#0F4761;font-weight:normal;'>11. Contact us</h1>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>If you have any questions, comments, or concerns about these terms and conditions, or our website, or our products or services, please contact us at stephen@walentik.net, or by mail at Walentik Software LLC, 6837 Revere Road, Parma Heights, Ohio 44130.</p>
                <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Aptos",sans-serif;'>&nbsp;</p>

            </div>
        </div>
    </div>
</section>