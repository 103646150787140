import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  errorText: string = null!;
  email: string;
  password: string;

  constructor(
    private authService: AuthService
  ) {
    this.email = '';
    this.password = '';
  }

  ngOnInit(): void {
    if(this.authService.isAuthenticated()){
      window.location.href = '/account/details';
    }
    
  }

  login() {


    this.authService.login(this.email, this.password).subscribe({
      next: (response: any) => {
        console.log(response);
        if (!!response && response.status === 200) {
          console.log("Successful login");
          window.location.href = '/account/details';
        } else if (!!response && !!response.error) {
          this.errorText = response.error.title + ": " + response.error.detail;
          console.log(this.errorText);
        }
      },
      error: (error: any) => {
        console.log("Error during login");
      }
    });
  }
}
